import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import MediaPage from '../components/mediaPage';


class IndexPage extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    const { data } = this.props;
    const { testimonials } = data.mediaEnJson;
    const { seo, international, german, intro } = data.mediaEnJson;

    return (
      <Layout data={this.props.data} location={this.props.location}>
        <SEO {...seo} />

        <MediaPage
          headerImage={data.file.childImageSharp.fixed}
          activeLangKey={this.props.pageContext.langKey}
          testimonials={testimonials}
          intro={intro}
          international={international}
          german={german}
        />
      </Layout>
    );
  }
}


export default IndexPage;

export const pageQuery = graphql`
  query MediaEnQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
      file(relativePath: { eq: "header-bg-medis.png" }) {
          childImageSharp {
              fixed(width: 2500 height: 793 quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
    homeDeJson {
        testimonials {
            name
            job
            blockquote
            image {
                childImageSharp {
                    fixed(width: 100, height: 100, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
    }
    mediaEnJson {
      seo {
        title
        description
        lang
        keywords
      }
        intro {
            preHeader
            header
        }
        german {
            preHeader
            header
            items {
                link
                published
                header
                authorName
            }
        }
        international {
            preHeader
            header
            items {
                link
                published
                header
                authorName
            }
        }
    }
  }
`;
